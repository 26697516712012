import React from 'react'
import PrivacyPolicyText from './PrivacyPolicyText'
import { LegalInfoContainer } from '../Layout/GlobalStyle'

export default function PrivacyPolicy() {
    return (
        <LegalInfoContainer>
            <div className="agreement-columns-grid">
                <div className="agreement-column">
                    <PrivacyPolicyText />
                </div>
            </div>
        </LegalInfoContainer>
    )
}
