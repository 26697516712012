import React from 'react'
import PrivacyPolicy from '../components/PrivacyPolicy'
import SEO from "../components/Layout/SEO"
import Layout from "../components/Layout"





const PrivacyPolicyPage = () => (
  <Layout seoProps={{title: "Privacy Policy"}}>
    <PrivacyPolicy />
  </Layout>
)

export default PrivacyPolicyPage